import styled from 'styled-components';
import { DropdownButton } from "react-bootstrap";

const MediumFont = 'Rubik-Regular';

const Button = styled.button`
  width: ${({width}) => width ? width : '210px'};
  height: 44px;
  border-radius: 4px;
  background-color: ${({bgColor}) => bgColor ? bgColor : '#159fc9'};
  border: none;
  position: relative;
  font-size: 10px;
  color: rgb(255,255,255);
  font-family: ${MediumFont};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 0;

  margin-top: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft && marginLeft };
  opacity: ${({disabled}) => disabled ? '0.5' : '1' };
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
  @media (max-width: 600px) {
    margin-top: 10px;
    float: ${({float}) => float && float };
    margin-bottom: ${({marginBottom}) => marginBottom && marginBottom };
  }
  .glyphicon {
    margin-right: 5px;
  }
`;

const CustomDropDown = styled(DropdownButton)`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 200px;
  padding: 0px 10px;
  height: 31px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  text-align: left;
  .title {
    width: 92%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 13px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  .caret {
    margin-left: 10px;
    float: right;
    margin-top: 0px;
    display: flex;
    align-self: center;
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border: 1px solid #cdcbcb;
    box-shadow: none;
    color: rgb(51,51,51);
  }
`;

export { Button, CustomDropDown };
