/* eslint-disable camelcase,no-undef */
import React, { Component, Suspense } from 'react';
import { DropdownItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  Layout, CustomDropDown, HeaderWrapper, MainSection, Button, Container, SideSection,
  IconsContainer, SortSection, StyledInput, AddButtonContainer, HeaderContainerPeopleHomeV2,
  HeaderTitlePeopleHomeV2, ViewContainer, ImageWrapper
} from './styles';
import { PaginationCircle } from '../Journeys/styles';
import { UserButton } from '../EngagementSection/styles';
import EmployeeContent from '../EmployeeContent';
import PeopleListView from '../PeopleListViewV2';
import PeopleGridView from '../PeopleGridViewV2';
import Waiting from '../Waiting';
const NewEmployeePopup = React.lazy(() => import('../NewEmployeePopupV2'));
import CSVMaker from '../CSVMaker';
import FeaturedChallenges from '../FeaturedChallenges';
import FeaturedEvents from '../FeaturedEvents';
import {
  RemoveCSVData, FetchEmployeeCount, DeleteEmployeeAPI, FetchSetRemoveAPI, PostAddEmployee,
  fetchSearchPeople, getListOfEmployees, getSortedEmployees, uploadBiometricForm, viewPhysicianFormAPI, getUserRoles, getCompanyDetails,
  sendFriendRequest
} from '../../redux/actions';
import ReactPDF from '../../components/ReactPDF';
const DeleteUserPopUp = React.lazy(() => import('../DeletePostPopup'));
import { getPermissionStatus } from '../../utils/methods';
const AddRolesPopup = React.lazy(() => import('../AddRolesPopup'));
const EditUserDetailsPopup = React.lazy(() => import('./EditUserDetailsPopup'));
import { withTranslation } from 'react-i18next';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';
const WAIT_INTERVAL = 1500;
const ENTER_KEY = 13;
const userType = [
  {
    name: 'All',
    id: 0
  },
  {
    name: 'Employee',
    id: 1
  },
  {
    name: 'Spouse',
    id: 2
  },
  {
    name: 'Dependent',
    id: 3
  }
];

class PeopleHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: null,
      department: null,
      departmentId: null,
      engagement: '',
      alphabetical: null,
      alphabeticalId: '',
      search: '',
      showPopUp: false,
      showGrid: props.searchAmigo,
      companyId: props.companyInfo['id'],
      engagementSort: false,
      showSort: false,
      pageNo: 1,
      loading: false,
      minLimit: 1,
      maxLimit: '',
      total: '',
      countingValues: '',
      locationDropDown: null,
      locationDropDownId: '',
      loader: false,
      showPDFModal: false,
      buttonText: '',
      pdfBase64: '',
      uploadUid: null,
      showDeleteUserPopUp: false,
      selectUserDropDown: 'All',
      selectUserDropDownId: 0,
      showRolesPopup: false,
      showUserPopUp: false
    };
    this.timer = null
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { paginationCount } = prevProps;
    if (paginationCount && (this.state.countingValues !== paginationCount)) {
      this.setState({
        total: Math.ceil(paginationCount / 24),
        countingValues: paginationCount
      }, () => {
        if (this.state.total < 6) {
          this.setState({
            maxLimit: this.state.total
          })
        }
        else {
          this.setState({
            maxLimit: 6
          })
        }
      })
    }
  }

  nextPageDetail = (e) => {
    e.preventDefault();
    const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight;
    if (bottom) {
      this.setState({
        pageNo: this.state.pageNo + 1
      }, () => {
        if (this.props.count > this.props.employeeData.length) {
          this.fetchEmployee();
        }
      })
    }
  };


  componentDidMount() {
    this.props.fetchListOfEmployees();
    const { getCompanyDetails } = this.props;
    this.fetchEmployee();
    const { companyId } = this.state;
    getCompanyDetails(companyId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.companyId !== this.state.companyId) {
      this.setState({
        search: '',
        pageNo: 1,
        engagementSort: false,
        showSort: false,
        department: null,
        departmentId: null,
        alphabetical: null,
        alphabeticalId: '',
      }, () => {
        this.fetchEmployee();
      })
    }
  }

  changeOrder = () => {
    this.setState({
      engagementSort: !this.state.engagementSort,
      showSort: true,
      pageNo: 1,
      selectedEmployee: null,
      alphabetical: null,

    }, () => {
      this.fetchEmployee();
    })
  };

  setorRemoveAction = (uid, status) => {
    const { fetchSetRemoveAPI } = this.props;
    let obj = {};
    obj['uid'] = uid;
    obj['status'] = !status ? 1 : 0;
    obj['company_id'] = this.state.companyId;
    obj['role'] = 11;
    fetchSetRemoveAPI(obj);
  };

  onSearchFunction = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchEmployee();
    }, WAIT_INTERVAL)
  };

  handleKeyDown = (evt) => {
    if (evt.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.fetchEmployee();
    }
  };

  changeLoading = () => {
    this.setState({
      loading: false
    })
  };

  fetchEmployee = () => {
    this.setState({
      uploadUid: null
    });
    let obj = {};
    const { pageNo, companyId, search, engagementSort, showSort, alphabeticalId,
      departmentId, department, locationDropDown, locationDropDownId, selectUserDropDownId } = this.state;
    if (pageNo === 1) {
      this.setState({ loading: true });
    } else {
      this.changeLoading();
    }

    const { fetchSearchPeople, fetchEmployeeCount } = this.props;
    obj['company_id'] = companyId;
    obj['name'] = search && search.trim();
    obj['page_no'] = pageNo;
    obj['limit'] = 24;
    if (showSort) {
      if (engagementSort) {
        obj['engagement_sort'] = 'DESC';
      }
      else {
        obj['engagement_sort'] = 'ASC';
      }
    }
    else {
      if (alphabeticalId === 1) {
        obj['name_sort'] = 'ASC';
      }
      else if (alphabeticalId === 2) {
        obj['name_sort'] = 'DESC';
      }
    }
    if (selectUserDropDownId === 0) {
      obj['user_type'] = 'all'
    }
    if (selectUserDropDownId === 1) {
      obj['user_type'] = 0
    }
    if (selectUserDropDownId === 2) {
      obj['user_type'] = 1
    }
    if (selectUserDropDownId === 3) {
      obj['user_type'] = 2
    }
    if (departmentId && department !== 'All Department') {
      obj['department_id'] = departmentId;
    }

    if (locationDropDownId && locationDropDown !== 'All Location') {
      obj['city_state_id'] = locationDropDownId;
    }
    if (pageNo === 1 && !showSort && search === '' && !departmentId && !alphabeticalId && !locationDropDownId) {
      fetchEmployeeCount(obj, true);
    } else {
      fetchEmployeeCount(obj, false);
    }
    fetchSearchPeople(obj, this.changeLoading);
    this.setState({
      selectedEmp: null,
      selectedEmployee: null
    })
  };

  deleteUserAction = (uid) => {
    const { deleteEmployeeAPI } = this.props;
    let obj = {};
    obj['user_uid'] = uid;
    deleteEmployeeAPI(obj);
    this.setState({
      selectedEmployee: null
    })
  };

  getCompany = (value) => {
    if (!this.state.loading) {
      const { companies, setCompany } = this.props;
      const id = companies.filter((company) => company.company_name === value)[0].id;
      this.setState({
        companyId: id,
        pageNo: 1,
        selectedEmployee: null,
        countingValues: '',
        minLimit: 1,
        maxLimit: '',
        departmentId: null,
        locationDropDownId: null
      });
      setCompany(id, value)
    }
  };

  addEmployeePopUp = () => {
    this.setState({
      showPopUp: !this.state.showPopUp
    });
  };

  userModal = () => {
    this.setState((prevState) => ({
      showUserPopUp: !prevState.showUserPopUp
    }));
  };

  showEmployeeDetails = (id) => {
    if (!this.state.loading) {
      this.setState({
        selectedEmployee: id
      })
    }
  };

  updateDropdown = (title, id, titleKey, idKey) => {
    this.setState({
      [titleKey]: title,
      [idKey]: id,
      pageNo: 1,
      engagementSort: false,
      showSort: false
    }, () => {
      this.fetchEmployee();
    })
  };


  onSelect = (value, state) => {
    this.setState({
      [state]: value
    }, () => {
      const { department, engagement, alphabetical, search } = this.state;
      this.sortEmployees(search, engagement, department, alphabetical);
    });
  };

  onChange = (e) => {
    e.preventDefault();
    if (!this.state.loading) {
      this.setState({
        search: e.target.value,
        pageNo: 1,
        engagementSort: false,
        showSort: false
      }, () => {
        this.onSearchFunction();
        const { department, engagement, alphabetical, search } = this.state;
        this.sortEmployees(search, engagement, department, alphabetical);
      });
    }
  };

  sortEmployees = (search, engagement, department, alphabetical) => {
    this.props.fetchSortedEmployees(search, engagement, department, alphabetical);
  };

  showView = (value) => {
    this.setState({
      showGrid: value
    })
  };

  getMenuItems = () => {
    const { companies } = this.props;
    return companies && companies.map((company) => company.company_name);
  };

  getPaginationCircle = () => {
    let circleButton = [];
    for (let i = this.state.minLimit; i <= this.state.maxLimit; i++) {
      circleButton.push(<PaginationCircle key={i} active={i == this.state.pageNo} onClick={() => this.setPagination(i)}><div>{i}</div></PaginationCircle>)
    }
    return circleButton;
  };

  setPagination = (activeIndex) => {
    if (activeIndex !== this.state.pageNo) {
      if (this.state.total <= 6) {
        this.setState({
          minLimit: 1,
          maxLimit: this.state.total,
          pageNo: activeIndex
        }, () => {
          this.fetchEmployee();
        });
      }
      else {
        if (activeIndex === 1) {
          this.setState({
            pageNo: activeIndex,
            maxLimit: 6,
            minLimit: 1
          }, () => {
            this.fetchEmployee();
          })
        }
        else {
          if (activeIndex + 3 > this.state.total) {
            this.setState({
              pageNo: activeIndex,
              maxLimit: this.state.total,
              minLimit: this.state.total - 4
            }, () => {
              this.fetchEmployee();
            });
          }
          else {
            this.setState({
              pageNo: activeIndex,
              maxLimit: activeIndex + 3,
              minLimit: activeIndex - 1
            }, () => {
              this.fetchEmployee();
            });
          }
        }
      }
    }
  };

  changeLoader = (bool) => {
    this.setState({
      loader: bool
    })
  };

  uploadPDFFile = (e, id) => {
    let file = e.target.files[0];
    if (file) {
      let fileArr = file.name.split('.');
      if (fileArr[fileArr.length - 1].toUpperCase() == 'PDF') {
        if ((file?.size / 1000000) <= 10) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              showPDFModal: true,
              pdfBase64: reader.result,
              buttonText: "Submit",
              uploadUid: id
            })
          };
        } else {
          toast.error('Please select PDF file less than 10MB');
          this.changeLoader(false);
          document.getElementById('biometric-user-admin-file').value = '';
        }
      } else {
        toast.error('Please select PDF file');
        this.changeLoader(false);
        document.getElementById('biometric-user-admin-file').value = '';
      }
    } else {
      this.changeLoader(false);
      document.getElementById('biometric-user-admin-file').value = '';
    }
  };

  removePDF = () => {
    this.setState({
      showPDFModal: false,
      pdfBase64: '',
      buttonText: "",
      uploadUid: null
    })
    document.getElementById('biometric-user-admin-file').value = '';
  }

  closePopup = () => {
    const { uploadUid, pdfBase64 } = this.state;
    if (pdfBase64.trim() != '' && uploadUid) {
      this.changeLoader(true);
      this.props.uploadBiometricForm({ physician_form: pdfBase64 }, this.changeLoader, uploadUid, this.fetchEmployee);
      this.setState({
        showPDFModal: false
      })
    }
  };

  showPDFData = (id) => {
    const { viewPhysicianFormAPI } = this.props;
    this.changeLoader(true);
    viewPhysicianFormAPI(id, this.changeLoader)

  };

  onToggleDeletePopup = () => {
    this.setState((prev) => ({ showDeleteUserPopUp: !prev.showDeleteUserPopUp }));
  };
  onToggleRolesPopup = (uid) => {
    this.setState((prev) => ({ showRolesPopup: !prev.showRolesPopup }), () => { this.state.showRolesPopup && this.props.getUserRoles(uid) });
  };
  render() {
    const { employeeList, companyInfo, employeeData, downloadCSV, csvData,
      removeCSVData, paginationCount, searchAmigo, history, role, userRolesList, count, companyDetails} = this.props;
    const { selectedEmployee, showPopUp, loader, showPDFModal, pdfBase64, buttonText, showDeleteUserPopUp, showRolesPopup, showUserPopUp } = this.state;
    if (_.isUndefined(employeeList) || _.isNull(employeeList) || !employeeData) {
      return <Waiting />
    }
    let selectedEmp = false;
    let selectedEmployeeDetail = false;
    if (!(_.isUndefined(employeeData)) && !(_.isNull(employeeData)) && employeeData.length > 0) {
      selectedEmp = selectedEmployee ? selectedEmployee : employeeData[0].uid;
      selectedEmployeeDetail = employeeData.filter((employee) => employee.uid === selectedEmp);
    }
    else {
      selectedEmp = false;
    }
    return (
      <Layout>
        {loader && <Waiting />}
        <Container>

          {this.state.loading && <Waiting />}

          <HeaderWrapper>
            <div>
              <HeaderContainerPeopleHomeV2 noPadding >
                <ImageWrapper>
                  <LazyImage div={'profile_img'} src={ImageUrl + "/images/PeoplePage/People_Group_Img.png"} alt="people_icon" />
                  <div>
                    <HeaderTitlePeopleHomeV2 marginRight={'0px'}><span>{count || 0}
                    </span>&nbsp;{this.props.t("Employee Directory")} </HeaderTitlePeopleHomeV2>
                  </div>
                </ImageWrapper>
                <AddButtonContainer marginRight="0">
                  {!searchAmigo && (getPermissionStatus("Add Employee", this.props.userPermissions)) &&
                    <Button onClick={this.addEmployeePopUp} float="right" marginBottom="10px" fontFamily="Rubik Medium" topPadding={"no"}>

                      add new employee
                    </Button>
                  }
                </AddButtonContainer>
              </HeaderContainerPeopleHomeV2>
              <div style={{ "width": "100%", "marginBottom": "15px", "marginTop": "15px", "border": "1px solid rgba(156,156,156,0.1)" }} />
              <SortSection>
                <div>
                  <StyledInput type="text" value={this.state.search} placeholder="Find a Employee"
                    onChange={(e) => this.onChange(e)}
                    onKeyDown={this.handleKeyDown}
                  />
                  <i className="fa fa-search" aria-hidden="true" />
                </div>
                <div className="float">
                  <div className="ipadIssue">
                    <div className="flex">
                      <div>
                        {(this.state.selectUserDropDownId === 0 || this.state.selectUserDropDownId === 3||this.state.selectUserDropDownId === 2||this.state.selectUserDropDownId === 1) && this.props.departmentDetails && this.props.departmentDetails.length &&
                          <div>
                            <CustomDropDown inputpadding="6px 0 6px 8px"
                              title={<div className="title">{this.state.selectUserDropDown || 'User Type'}</div>}
                              id="role-sort"
                            >
                              {
                                userType.map((list, index) => ((index===0||index===1||(companyDetails && companyDetails.length > 0 && companyDetails[0] && companyDetails[0].show_spouse===1?index===3:'')||(companyDetails && companyDetails.length > 0 && companyDetails[0] && companyDetails[0].show_dependent===1?index===2:''))&&
                                  <DropdownItem key={index} onSelect={() => this.updateDropdown(list.name, list.id, 'selectUserDropDown', 'selectUserDropDownId')}>{list.name}</DropdownItem>
                                ))
                              }
                            </CustomDropDown>
                          </div>
                        } 
                      </div>
                      {(this.state.selectUserDropDownId === 0 || this.state.selectUserDropDownId === 3||this.state.selectUserDropDownId === 2||this.state.selectUserDropDownId === 1) && this.props.departmentDetails && this.props.departmentDetails.length &&
                        <div>
                          <CustomDropDown inputpadding="6px 0px 0px 9px"
                            type="button" title={this.state.department ? <div className="title">{this.state.department}</div> : <div className="title">{"Department"}</div>}
                            id="department-sort"
                          >
                            {
                              this.props.departmentDetails.map((list, index) => (
                                <DropdownItem key={index} onSelect={() => this.updateDropdown(list.department, list.id, 'department',
                                  'departmentId')}>{list.department}</DropdownItem>
                              ))
                            }
                          </CustomDropDown>
                        </div>
                      }
                      {(this.state.selectUserDropDownId === 0 || this.state.selectUserDropDownId === 3||this.state.selectUserDropDownId === 1||this.state.selectUserDropDownId === 2) && this.props.locationDetails && this.props.locationDetails.length &&
                        <div>
                          <CustomDropDown inputdisplay="block" inputpadding="6px 0 6px 8px"
                            title={<div className="title">{this.state.locationDropDown || 'Location'}</div>}
                            id="role-sort"
                          >
                            {
                              this.props.locationDetails.map((list, index) => (
                                <DropdownItem key={index} onSelect={() => this.updateDropdown(list.location, list.id, 'locationDropDown', 'locationDropDownId')}>{list.location}</DropdownItem>
                              ))
                            }
                          </CustomDropDown>
                        </div>
                      }
                      <div>
                        <ViewContainer>
                          <IconsContainer>
                            {this.state.showGrid ? <img src="/public/images/hamburger_inactive.svg" alt="hamburger-menu" onClick={() => this.showView(false)} /> : <img src="/public/images/hamburger_active.svg" alt="hamburger-menu" onClick={() => this.showView(false)} />}
                            {this.state.showGrid ? <img src="/public/images/grid_active.svg" alt="grid-icon" onClick={() => this.showView(true)} /> : <img src="/public/images/grid_inactive.svg" alt="grid-icon" onClick={() => this.showView(true)} />}
                          </IconsContainer>
                        </ViewContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </SortSection>
              {searchAmigo &&
                <Button onClick={() => history.push('/community/invite')} float="right" marginBottom="10px">
                  Search buddies
                </Button>
              }
            </div>
          </HeaderWrapper>
          <div style={{ "width": "100%", "display": "flex" }}>
            <MainSection >
              {
                this.state.showGrid ?
                  <PeopleGridView
                    employeeList={employeeData}
                    showEmployeeDetails={this.showEmployeeDetails}
                    selectedEmployee={selectedEmp}
                    searchAmigo={searchAmigo}
                  /> :
                  <PeopleListView
                    employeeList={employeeData}
                    showEmployeeDetails={this.showEmployeeDetails}
                    selectedEmployee={selectedEmp}
                    changeOrder={this.changeOrder}
                    searchAmigo={searchAmigo}
                    history={history}
                  />
              }
              {paginationCount > 25 &&
                <div className="pagination">
                  {this.state.pageNo > 1 &&
                    <UserButton
                      width="30px"
                      height="34px"
                      Align={1}
                      float={1}
                      fontSize="14px"
                      bgColor="#dedcdc"
                      capitalize={1}
                      onClick={() => this.setPagination(this.state.pageNo - 1)}
                    >
                      <img src="/public/images/CompanyDashBoardV2/NewIcon/leftcrossIcon.png" />
                    </UserButton>
                  }
                  {this.getPaginationCircle()}
                  {this.state.pageNo != this.state.total &&
                    <UserButton
                      width="30px"
                      height="34px"
                      Align={1}
                      marginLeft="10px"
                      fontSize="14px"
                      bgColor="#dedcdc"
                      capitalize={1}
                      onClick={() => this.setPagination(this.state.pageNo + 1)}
                    >
                      <img src="/public/images/CompanyDashBoardV2/NewIcon/rightcrossIcon.png" />
                    </UserButton>
                  }
                </div>
              }
            </MainSection>
            {!searchAmigo
              ?
              <SideSection>
                {selectedEmployeeDetail && selectedEmployeeDetail[0] && selectedEmp && employeeData.length > 0 &&
                  <div>
                    <EmployeeContent
                      deleteUserAction={this.onToggleDeletePopup}
                      setOrRemoveRole={this.setorRemoveAction}
                      employee={selectedEmployeeDetail[0]}
                      searchAmigo={searchAmigo}
                      uploadPDFFile={this.uploadPDFFile}
                      Admin={role == 'ADMIN' ? true : false}
                      companyId={companyInfo.id}
                      history={history}
                      showPDFData={this.showPDFData}
                      userPermissions={this.props.userPermissions}
                      showRolesPopup={showRolesPopup}
                      onToggleRolesPopup={this.onToggleRolesPopup}
                      userModalPopup={this.userModal}
                    />
                    <DeleteUserPopUp
                      title="DELETE USER"
                      subContent="This user will be removed at the end of the month."
                      showModal={showDeleteUserPopUp}
                      onClose={this.onToggleDeletePopup}
                      deleteUser={this.deleteUserAction}
                      uid={selectedEmployeeDetail[0].uid}
                    />
                  </div>
                }
              </SideSection>
              :
              <SideSection noBackground>
                <FeaturedChallenges history={history} />
                <div className="events">
                  <FeaturedEvents history={history} />
                </div>
              </SideSection>
            }
          </div>
        </Container>
        { !searchAmigo &&
          <NewEmployeePopup companyId={this.state.companyId} postAddEmployee={this.props.postAddEmployee} 
            show={showPopUp} showModalPopUp={this.addEmployeePopUp} companyAuthDetails={companyDetails}/>
        }
        {downloadCSV && !(_.isUndefined(csvData)) && !(_.isNull(csvData)) && <CSVMaker data={csvData} removeCSVData={removeCSVData} />}
        <ReactPDF
          showModal={showPDFModal}
          closeModal={this.closePopup}
          pdfBase64={pdfBase64}
          buttonText={buttonText}
          employee={selectedEmployeeDetail[0]}
          removePDF={this.removePDF}
          showCrossMarker={true}
        />
        {   
          showRolesPopup && <Suspense fallback={<Waiting/>}> <AddRolesPopup showModal={showRolesPopup} closeModal={this.onToggleRolesPopup} 
            employee={selectedEmployeeDetail && selectedEmployeeDetail[0]} userRolesList={userRolesList} companyId={companyInfo.id}/>
          </Suspense>
        }
        {
          showUserPopUp ? <Suspense fallback={<Waiting/>}><EditUserDetailsPopup showUserModal={showUserPopUp} onClose={this.userModal} employee={selectedEmployeeDetail && selectedEmployeeDetail[0]} locationDetails={this.props.locationDetails} departmentDetails={this.props.departmentDetails} fetchEmployee={this.fetchEmployee} /></Suspense> : null
        }
      </Layout>
    )
  }
}

PeopleHome.defaultProps = {
  companyInfo: { id: 1, name: '' }
};

PeopleHome.propTypes = {
  fetchListOfEmployees: PropTypes.func.isRequired,
  employeeList: PropTypes.array,
  fetchSortedEmployees: PropTypes.func.isRequired,
  sortedList: PropTypes.array,
  history: PropTypes.object,
  employeeData: PropTypes.array,
  count: PropTypes.number,
  companyInfo: PropTypes.object,
  companies: PropTypes.array,
  fetchSearchPeople: PropTypes.func,
  postAddEmployee: PropTypes.func,
  fetchSetRemoveAPI: PropTypes.func,
  deleteEmployeeAPI: PropTypes.func,
  fetchEmployeeCount: PropTypes.func,
  setCompany: PropTypes.func,
  downloadCSV: PropTypes.bool,
  csvData: PropTypes.array,
  removeCSVData: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  paginationCount: PropTypes.number,
  searchAmigo: PropTypes.bool,
  uploadBiometricForm: PropTypes.func,
  role: PropTypes.string,
  viewPhysicianFormAPI: PropTypes.func,
  biometricPdfFile: PropTypes.string,
  userPermissions: PropTypes.array,
  userRolesList: PropTypes.array,
  getUserRoles: PropTypes.func,
  getCompanyDetails: PropTypes.func,
  companyDetails: PropTypes.func,
  t: PropTypes.func,
  sendFriendRequest: PropTypes.func,

};

const mapStateToProps = (state) => ({
  employeeList: state.peopleHome.employeeList,
  sortedList: state.peopleHome.sortedList,
  employeeData: state.peopleHome.employeeData,
  count: state.peopleHome.count,
  downloadCSV: state.peopleHome.csvMake,
  csvData: state.peopleHome.csvData,
  locationDetails: state.companyDashboard.companyPeopleLocation,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  paginationCount: state.peopleHome.paginationCount,
  biometricPdfFile: state.profileData.biometricPdfFile,
  userPermissions: state.profileData.userPermissions,
  userRolesList: state.peopleHome.userRolesList,
  companyDetails: state.peopleHome.companyDetails,
  sentRequest: state.social.sentRequest
});

const mapDispatchToProps = (dispatch) => ({
  fetchListOfEmployees: () => dispatch(getListOfEmployees()),
  fetchSortedEmployees: (search, engagement, department, alphabetical) => dispatch(getSortedEmployees(search, engagement, department, alphabetical)),
  fetchSearchPeople: (data, cb) => dispatch(fetchSearchPeople(data, cb)),
  postAddEmployee: (data) => dispatch(PostAddEmployee(data)),
  fetchSetRemoveAPI: (obj) => dispatch(FetchSetRemoveAPI(obj)),
  deleteEmployeeAPI: (obj) => dispatch(DeleteEmployeeAPI(obj)),
  fetchEmployeeCount: (obj, bool) => dispatch(FetchEmployeeCount(obj, bool)),
  removeCSVData: () => dispatch(RemoveCSVData()),
  uploadBiometricForm: (obj, cb, userId, fetchEmployee) => dispatch(uploadBiometricForm(obj, cb, userId, fetchEmployee)),
  viewPhysicianFormAPI: (uid, cb) => dispatch(viewPhysicianFormAPI(uid, cb)),
  getUserRoles: (uid) => dispatch(getUserRoles(uid)),
  getCompanyDetails: (companyId) => dispatch(getCompanyDetails(companyId)),
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PeopleHome));